import React, { createContext, useContext, useState, useEffect } from "react";

interface ContextType {
    tooltipType: string,
    tooltipData: any,
    tooltipPosition: { x: number, y: number }

    openTooltip?: Function
}

export const TooltipDataContext = createContext<ContextType>({
    tooltipType: '',
    tooltipData: {},
    tooltipPosition: {x: 0, y: 0},
    openTooltip: () => {}
});


export const TooltipDataContextProvider = ({ children }: { children: any }) => {
    const [tooltipType, setTooltipType] = useState('');
    const [tooltipData, setTooltipData] = useState(undefined);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

    const openTooltip = (e: MouseEvent, data: any) => {
        console.log({ e });

        setTooltipPosition({
            x: e.clientX,
            y: e.clientY
        })
    };

    return (
        <TooltipDataContext.Provider value={{ tooltipType, tooltipData, tooltipPosition, openTooltip }}>
            {children}
        </TooltipDataContext.Provider>
    );
};

export const useTooltipDataContextProvider = () => {
    return useContext(TooltipDataContext);
};

// export { TooltipDataContext, TooltipDataContextProvider };


