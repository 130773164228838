import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { Box, MenuItem, MenuList, Paper, InputBase, Typography, ClickAwayListener, Divider, Fab } from '@mui/material';
import numeral from 'numeral';
import CircularProgress from '@mui/material/CircularProgress';

import { SEARCH_QUERY } from '@state/byModel/Stocks/stocks.queries';
import useSearchParams from '@state/byModel/Global/useSearchParams.effect';
import { GET_BACK_SCREENS } from "@state/byModel/BackScreens/BackScreens.queries";
import { BackScreenProvider, useBackScreenProvider } from "@components/routes/BackScreen/BackScreen.context";
import { GET_USER_KEYS } from '@state/byModel/User/UserKey.localQueries';
import { GET_LOCAL_SCREENS, UPDATE_LOCAL_SCREENS } from "@state/byModel/SelectedBackScreens/SelectedBackScreens.localQueries";

import { useStyles } from './styles';

import "./style.sass";

export default function StockBackScreenerSearcher() {

    const { allSearchParams, getNewSearchParamsString, updateParams } = useSearchParams();
    const classes = useStyles().classes;

    const [name, setName] = useState<string>('');
    const [selected, setSelected] = useState<boolean>(false);

    const { backScreeners } = useBackScreenProvider();
    const { loading: UserKeysLoading, error: UserKeysError, data: UserKeysData } = useQuery(GET_USER_KEYS);
    // const [localUserKey, setLocalUserKey] = useState(true);
    const [updateScreens] = useMutation(UPDATE_LOCAL_SCREENS);
    const { loading: localStorageScreensLoading, error: localStorageScreensError, data: localStorageScreensData, refetch: updateScreens_ } = useQuery(GET_LOCAL_SCREENS);

    const { loading: backScreenOptionsLoading, error: backScreenOptionsError, data: backScreenOptions } = useQuery(GET_BACK_SCREENS, {
        variables: {
            userId: UserKeysData?.userKeys?.length > 0 ? UserKeysData?.userKeys[0].id : ""
        },
    });

    const [searchStocks, { loading, error, data: searchStocks_ }] = useLazyQuery(SEARCH_QUERY);
    // const [updateRatioCollections] = useMutation(UPDATE_SELECTED_RATIO_COLLECTIONS);

    const backScreenOptions_: { value: string, title: string, type: string }[] = backScreenOptions?.getBackScreens ?
        backScreenOptions?.getBackScreens?.map((c: any) => ({
            ...c,
            // value: c.id,
            // title: c.name,
            filters: c.filters || [],
            rows: c.rows || [],
            type: c.isOwnedByPlatform ? 'system' : c.isOwnedByUser ? 'yours' : 'other user\'s'
        }))?.filter(b => !!name ? b.name.match(name)?.length : true) : [];


    // console.log({
    //     backScreenOptions_
    // });

    const selectedBackScreen = localStorageScreensData?.screens?.length ? localStorageScreensData?.screens?.[0] : [];

    const handlePickerToggle = ({ v }: any) => {
        updateScreens({
            variables: {
                screens: [v]
            }
        });
        setName('');
        setSelected(false);
    }

    // console.log({ localStorageScreensData, selectedBackScreen, searchStocks_ });

    const handleInputChange = (e: any) =>
        setName(e ? e.target.value : '');

    useEffect(() => {
        name.length > 1 && searchStocks({ variables: { name } })
    }, [name])

    // Typography
    return (
        <ClickAwayListener onClickAway={() => {
            setSelected(false);
        }}>
            <div className="StockSearcher_container">
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    {/* {selectedBackScreen ? (
                        <div className={classes.inputInput}>BackScreen: {selectedBackScreen?.name}</div>
                    ) : ( */}
                    <InputBase
                        onChange={handleInputChange}
                        value={name}
                        placeholder="Search for Backscreens or Stocks"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search' }}
                        onClick={() => setSelected(true)}
                    />
                    {/* )} */}
                </div>

                {selected && (
                    <Paper
                        className="StockSearcher_SearchResults"
                        elevation={3}
                    >
                        <MenuList>

                            <Box pt={1}>
                                <Typography variant="overline" gutterBottom sx={{ padding: '0 12px' }}>
                                    Stocks
                                </Typography>
                            </Box>
                            {searchStocks_?.searchStocks?.length ? searchStocks_.searchStocks.map((d: any, i: number) => (
                                <MenuItem
                                    className="list-group-item"
                                    key={d.code + " " + i}
                                    component={Link}
                                    to={
                                        {
                                            pathname: `/stock/${d.code}.${d.EODExchange}`,
                                            search: (getNewSearchParamsString({ keysToRemove: ['ticker'] }) as string)
                                        }
                                    }
                                    onClick={() => handlePickerToggle(({ v: false }))}
                                >
                                    <Typography noWrap={true}>
                                        {d.name} ({d.code})・ {d.currency_symbol}{numeral(d.market_capitalization).format('(0.00a)')} ・{d.sector} ・{d.industry}
                                    </Typography>
                                </MenuItem>
                            )) : <Box pt={0} pb={2}><Typography variant="caption" gutterBottom sx={{ padding: '0 10px' }}>
                                No stocks by that search term
                            </Typography>
                            </Box>}



                            <Divider />



                            <Box position="relative">
                                {UserKeysData?.userKeys?.length > 0 ? (
                                    <Box
                                        position="absolute"
                                        right={0}
                                        top="-13px"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="end"
                                        m={1}
                                        mb={-6}
                                    >
                                        <Fab
                                            size="small"
                                            color="primary"
                                            aria-label="add"
                                            onClick={() => updateParams({ search: getNewSearchParamsString({ paramsToAdd: { newBackScreen: true } }) })}
                                        >
                                            <AddIcon />
                                        </Fab>
                                    </Box>
                                ) : null}
                                <Box pt={1}>
                                    <Typography variant="overline" gutterBottom sx={{ padding: '0 12px' }}>
                                        Back Screeners
                                    </Typography>
                                </Box>
                            </Box>
                            {backScreenOptionsLoading && (
                                <MenuItem disabled={true}>
                                    <CircularProgress />
                                </MenuItem>
                            )}
                            {backScreenOptions_?.length ? backScreenOptions_.map((d: any, i: number) => (
                                <MenuItem
                                    className="list-group-item"
                                    key={d.code + " " + i}
                                    component={Link}
                                    to={
                                        {
                                            pathname: `/backScreen/${d.name}/${d.id}`,
                                        }
                                    }
                                    onClick={() => handlePickerToggle(({ v: d }))}
                                >
                                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                                        <Typography noWrap={true}>
                                            {d.name}
                                        </Typography>
                                        <Typography variant="caption" noWrap={true} color="text.secondary">
                                            {d.type}
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            )) : <Box pt={0} pb={2}>
                                <Typography variant="caption" gutterBottom sx={{ padding: '0 10px' }}>No backscreeners by that search term</Typography>
                            </Box>}


                        </MenuList>
                    </Paper>
                )}
            </div>
        </ ClickAwayListener>
    );
}
