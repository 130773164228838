import React, { FunctionComponent } from "react";
import { LinkedIn } from '@mui/icons-material';
import {
    Paper,
    Box,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    TextField,
    Fab,
    Chip,
    Link,
    SvgIcon
} from '@mui/material';
import { discordSVGPath, discordSVGViewBox } from "@assets/icon-paths/discord";
import useSearchParams from '@state/byModel/Global/useSearchParams.effect';


import "./style.sass";

export const AlphaWarningModal: FunctionComponent<{}> = ({ }) => {

    const { allSearchParams, getNewSearchParamsString, updateParams } = useSearchParams();

    const handleClose = () => {
        updateParams({ search: getNewSearchParamsString({ keysToRemove: ['showWarning'] }) });
    };

    return (
        <Dialog open={!!allSearchParams.showWarning} onClose={handleClose}>
            <Paper>
                <Box
                    width={455}
                    maxWidth="calc(100vw - 65px)"
                >
                    <DialogTitle>
                        🥽 Work in progress 🧪
                    </DialogTitle>
                    <DialogContent>

                        <Box mt={-2}>
                            <p>
                                This Tool is not ready yet.
                                <br />
                                <br />
                                But if you find anything you like please get in touch with me as I'm exploring and considering new ideas.
                                <br />
                                <br />
                                <Link href="https://www.linkedin.com/in/esperancajs/" target="_blank">
                                    <Button variant="contained">
                                        <Box display="flex" alignItems="center">
                                            <Box mr={1}>
                                                Send me a message
                                            </Box>
                                            <LinkedIn fontSize="inherit" />
                                        </Box>
                                    </Button>
                                </Link>

                            </p>
                        </Box>
                    </DialogContent>
                    {/* <DialogActions>
                        <Button onClick={handleClose} color="secondary">
                            Ok
                        </Button>
                    </DialogActions> */}
                </Box>
            </Paper>
        </Dialog>
    );
}
