import React, { FunctionComponent } from 'react';
import { useQuery } from "@apollo/client";
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import { Link as Link_ } from "react-router-dom";
import { Grid, Box, Link, Badge, Avatar, Button } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

import logoImg from '@assets/e-transparent.png';
import useSearchParams from '@state/byModel/Global/useSearchParams.effect';
import { GET_USER_KEYS } from "@state/byModel/User/UserKey.localQueries";
import { GET_USER_BY_ID } from '@state/byModel/User/User.queries';
import StockSearcher from '@components/shared/StockSearcher';
import StockBackScreenerSearcher from '@components/shared/StockBackScreenerSearcher';
import HeaderTitle from '@components/shared/HeaderTitle';
import LoginModal from './LoginModal';
import SettingsModal from './SettingsModal';
import NewBackScreenModal from './NewBackScreenModal';
import AlphaWarningModal from './AlphaWarningModal';

import './style.sass';

export const Header: FunctionComponent<{ clearCache : any}> = ({ clearCache }) => {
    const { loading: UserKeysLoading, error: UserKeysError, data: UserKeysData } = useQuery(GET_USER_KEYS);
    const { loading: UserByIdLoading, error: UserByIdError, data: UserByIdData } = useQuery(GET_USER_BY_ID, {
        variables: { id: UserKeysData?.userKeys?.length && UserKeysData?.userKeys[0].id },
        skip: !UserKeysData?.userKeys?.length
    });

    const user = UserByIdData?.getUserById;

    // console.log({
    //     UserKeysData,
    //     key: UserKeysData?.userKeys?.length && UserKeysData?.userKeys[0].id,
    //     UserByIdData
    // });

    const { allSearchParams, getNewSearchParamsString, updateParams } = useSearchParams();

    const handleClickOnAlphaWarning = () => {
        updateParams({ search: getNewSearchParamsString({ paramsToAdd: { showWarning: true } }) });
    };

    return (
        <>
            <LoginModal />
            <SettingsModal clearCache={clearCache}/>
            <NewBackScreenModal />
            <AlphaWarningModal />
            <AppBar position="fixed">
                <Toolbar variant="dense">
                    <Box display="flex" width={'100%'} justifyContent="space-between">

                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Box mr={1}>
                                    <Link_ color="inherit" to={{
                                        pathname: `/home`
                                    }}>
                                        <img className="logo" src={logoImg} />
                                    </Link_>
                                </Box>
                                <Badge
                                    className="hide-on-mobile"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    color="primary"
                                    badgeContent={<div style={{ cursor: 'pointer' }} onClick={handleClickOnAlphaWarning}>🔬 alpha</div>}
                                >

                                    <Grid container direction="column" justify="center" pr={1}>
                                        <Grid item >
                                            <Link component={Link_} color="inherit" underline="hover" to={{
                                                pathname: `/home`
                                            }}>
                                                <Typography variant="h6">
                                                    Equity Contrast
                                                </Typography>
                                            </Link>
                                        </Grid>
                                        <Grid item>
                                            <Box mt={-1}>
                                                <Typography variant="subtitle2" >
                                                    compare stocks
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Badge>
                            </Box>

                            <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="center"
                                alignItems="center"
                                paddingLeft={{
                                    sm: 0,
                                    md: 5
                                }}
                                // className="hide-on-mobile"
                                // mr={-20}
                            >
                                <Box
                                    display="flex"
                                    position="relative"
                                >
                                    <StockBackScreenerSearcher />
                                </Box>
                                {/* <Box
                                    display="flex"
                                    width={210}
                                >
                                    <StockSearcher />
                                </Box> */}
                            </Box>
                        </Box>



                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                            ml={-2}
                        >
                            <HeaderTitle />
                        </Box>

                        <Box display="flex" overflow="visible" alignItems="center">
                            {/* <StockSearcher /> */}
                            <Box mr={1} className="hide-on-mobile">
                                <Typography variant="caption">
                                    Currency in <b>USD</b>
                                </Typography>
                            </Box>

                            <Box>
                                <IconButton
                                    onClick={() => updateParams({ search: getNewSearchParamsString({ paramsToAdd: { openSettings: true } }) })}
                                >
                                    <SettingsIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box height={58} />
        </>

    );
}

export default Header;

