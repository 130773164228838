import React, { FunctionComponent, useState, useEffect } from "react";
import { useParams, useRouteMatch, useLocation } from "react-router-dom";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { LinkedIn } from '@mui/icons-material';
import { LightMode, SettingsBrightness, DarkModeOutlined } from '@mui/icons-material';
import {
    Paper,
    Box,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    TextField,
    Fab,
    Chip,
    Link,
    Autocomplete,
    ButtonGroup,
    Divider,
    Avatar,
    ToggleButtonGroup,
    ToggleButton
} from '@mui/material';

import { GET_USER_BY_ID } from '@state/byModel/User/User.queries';
import { GET_USER_KEYS, ADD_USER_KEY } from "@state/byModel/User/UserKey.localQueries";
import { GET_COLOR_SCHEME, EDIT_COLOR_SCHEME } from "@state/byModel/ColorTheme/colorTheme.queries";
import useSearchParams from '@state/byModel/Global/useSearchParams.effect';
import RatioCollectionPicker from "@components/routes/StockPage/RatioCollections/RatioCollectionPicker";
import { GET_RATIO_COLLECTIONS } from '@state/byModel/Calculations/calculations.queries';
import ComparisonsPicker from '@components/shared/ComparisonsPicker';

import "./style.sass";

export const SettingsModal: FunctionComponent<{ clearCache: any }> = ({ clearCache }) => {

    const { allSearchParams, getNewSearchParamsString, updateParams } = useSearchParams();
    const [userKeyValue, setUserKeyValue] = useState('');
    const [isinvalidKeyError, setIsInvalidKeyError] = useState(false);
    const { loading: UserKeyLoading, error: UserKeyError, data: UserKeyData } = useQuery(GET_USER_KEYS);
    const [addUserKey] = useMutation(ADD_USER_KEY);
    const { data: colorSchemeData } = useQuery(GET_COLOR_SCHEME);
    const [editColorScheme] = useMutation(EDIT_COLOR_SCHEME);

    const [localScheme, setLocalScheme] = React.useState('');

    const handleSchemeSelect = (event: React.MouseEvent<HTMLElement>, mode: string) => {
        setLocalScheme(mode);

        editColorScheme({
            variables: mode ? {
                mode
            } : {}
        });
    }

    const {
        loading: ratioCollections_loading, error: ratioCollections_error, data: ratioCollectionsQ
    } = useQuery(GET_RATIO_COLLECTIONS, {
        variables: {
            userId: UserKeyData?.userKeys?.length > 0 ? UserKeyData?.userKeys[0].id : ""
        },
        // skip: !stock
    });

    const ratioCollections = ratioCollectionsQ?.getRatioCollections;


    const [getUser, { loading, error, data }] = useLazyQuery(GET_USER_BY_ID);
    // const [saveRatioCollection] = useMutation(SAVE_RATIO_COLLECTION);


    const { loading: UserKeysLoading, error: UserKeysError, data: UserKeysData } = useQuery(GET_USER_KEYS);
    const { loading: UserByIdLoading, error: UserByIdError, data: UserByIdData } = useQuery(GET_USER_BY_ID, {
        variables: { id: UserKeysData?.userKeys?.length && UserKeysData?.userKeys[0].id },
        skip: !UserKeysData?.userKeys?.length
    });

    const user = UserByIdData?.getUserById;

    // console.log({ UserKeyData });

    useEffect(() => {
        // console.log({
        //     user: data?.getUserById
        // });

        if (data?.getUserById) {
            addUserKey({
                variables: {
                    id: userKeyValue
                }
            });
            handleClose();
        } else if (data) {
            setIsInvalidKeyError(true);
        }
    }, [data?.getUserById]);

    const handleClose = () => {
        updateParams({ search: getNewSearchParamsString({ keysToRemove: ['openSettings'] }) })
    };

    const handleLogin = () => {
        getUser({ variables: { id: userKeyValue } });
    }

    return (
        <Dialog open={!!allSearchParams.openSettings} onClose={handleClose}>
            <Paper>
                <Box
                    width={455}
                    maxWidth="calc(100vw - 65px)"
                >
                    <DialogTitle>
                        Settings
                    </DialogTitle>
                    <DialogContent>

                        <Box display="flex" justifyContent="space-around" flexDirection="column">
                            <Box display="flex" flexDirection="column">
                                <Typography variant="overline" fontSize="12px" color="text.secondary">Color Theme</Typography>
                                <Box >
                                    <ToggleButtonGroup
                                        value={localScheme}
                                        onChange={handleSchemeSelect}
                                        exclusive
                                        aria-label="Basic button group"
                                        size="medium"
                                        color="primary"
                                    >
                                        <ToggleButton
                                            value="light"
                                            sx={{ textTransform: 'capitalize', pr: { xs: 2, sm: 3 } }}
                                        >
                                            <LightMode sx={{ mx: 2, fontSize: 18 }} />
                                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>Light</Box>
                                        </ToggleButton>
                                        <ToggleButton
                                            value=""
                                            sx={{ textTransform: 'capitalize', pr: { xs: 2, sm: 3 } }}
                                        >
                                            <SettingsBrightness sx={{ mx: 2, fontSize: 20 }} />
                                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>System</Box>
                                        </ToggleButton>
                                        <ToggleButton
                                            value="dark"
                                            sx={{ textTransform: 'capitalize', pr: { xs: 2, sm: 3 } }}
                                        >
                                            <DarkModeOutlined sx={{ mx: 2, fontSize: 18 }} />
                                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>Dark</Box>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Box>
                            </Box>

                            <Divider flexItem sx={{ margin: '25px 10px' }} />

                            <Box py={2}>
                                <RatioCollectionPicker ratioCollections={ratioCollections} />
                            </Box>

                            {/* <Divider flexItem sx={{ margin: '25px 10px' }} />

                            <Box pt={2} pb={2}>
                                <ComparisonsPicker />
                            </Box> */}

                            <Divider flexItem sx={{ margin: '25px 10px' }} />

                            <Box>
                                {/* <Avatar alt="You" src="http://www.gstatic.com/tv/thumb/persons/73202/73202_v9_bb.jpg" /> */}
                                {user ? (
                                    <Box display="flex">
                                        <Avatar
                                            alt="You"
                                            title={user?.name}
                                            src={user?.avatarUrl || 'http://www.gstatic.com/tv/thumb/persons/73202/73202_v9_bb.jpg'}
                                            onClick={() => clearCache()}
                                        />
                                        <Box ml={2}>
                                            <Button variant="contained" onClick={() => clearCache()}>
                                                Logout
                                            </Button>
                                        </Box>
                                    </Box>
                                ) : (
                                    <Button
                                        onClick={() => updateParams({ search: getNewSearchParamsString({ paramsToAdd: { isRegistering: true } }) })}
                                    >
                                        Login
                                    </Button>
                                )}
                            </Box>

                        </Box>


                    </DialogContent>
                    {/* <DialogActions>
                    <Button onClick={handleLogin} color="secondary">
                        Login
                    </Button>
                </DialogActions> */}
                </Box>
            </Paper>
        </Dialog>
    );
}
