import React, { FunctionComponent } from 'react';
import { Box } from '@mui/material';

import { useTooltipDataContextProvider } from "@state/byModel/Tooltip/tooltip.context";

import './style.sass';

export const Tooltip: FunctionComponent<{}> = ({}) => {
    const { tooltipData, tooltipPosition } = useTooltipDataContextProvider();
    return (
        <Box position="fixed" display="none" sx={{ background: 'red', top: tooltipPosition.y, left: tooltipPosition.x, zIndex: '10000' }} >
            <p>Tooltip</p>

            <pre>{JSON.stringify(tooltipData, null, 4)}</pre>
        </Box>
    );
}

