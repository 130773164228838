import { gql } from '@apollo/client';
// import RandomID from "random-id";
// import { GET_LOCAL_SCREENS } from "./FilterOptions.localQueries";

export const selectedComparisonsResolvers = {
  Mutation: {
    updateSelectedComparisons: (_: any, variables: any, { cache }: any) => {
      // const { screens } = cache.readQuery({ query: GET_LOCAL_SCREENS });

      cache.writeQuery({
        query: gql`
          query {
            selectedComparisons {
              value
              title
              type
              filters
            }
          }
        `,
        data: {
          selectedComparisons: [...variables.selectedComparisons]
        }
      });

      return variables.selectedComparisons;
    }
  }
};