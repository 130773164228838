import React, { FunctionComponent, useState, useEffect } from "react";
import { useParams, useRouteMatch, useLocation, useHistory } from "react-router-dom";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { LinkedIn } from '@mui/icons-material';
import {
    Paper,
    Box,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    TextField,
    Fab,
    Chip,
    Link,
    Autocomplete
} from '@mui/material';

import { SAVE_BACK_SCREEN } from "@state/byModel/BackScreens/BackScreens.queries";
import { GET_USER_BY_ID } from '@state/byModel/User/User.queries';
import { GET_USER_KEYS, ADD_USER_KEY } from "@state/byModel/User/UserKey.localQueries";
import { GET_LOCAL_SCREENS, UPDATE_LOCAL_SCREENS } from "@state/byModel/SelectedBackScreens/SelectedBackScreens.localQueries";
import useSearchParams from '@state/byModel/Global/useSearchParams.effect';
import { GET_BACK_SCREENS } from "@state/byModel/BackScreens/BackScreens.queries";

import "./style.sass";

export const NewBackScreenModal: FunctionComponent<{}> = ({ }) => {

    const [screenName, setScreenName] = useState('');
    const [isinvalidKeyError, setIsInvalidKeyError] = useState(false);
    const { loading: UserKeyLoading, error: UserKeyError, data: UserKeysData } = useQuery(GET_USER_KEYS);
    const [addUserKey] = useMutation(ADD_USER_KEY);
    const [saveSingleBackScreen, { loading: saveSingleBackScreen_loading }] = useMutation(SAVE_BACK_SCREEN);
    const history = useHistory();

    const { allSearchParams, getNewSearchParamsString, updateParams } = useSearchParams();
    const [updateScreens] = useMutation(UPDATE_LOCAL_SCREENS);

    const { refetch: refetch_backScreens } = useQuery(GET_BACK_SCREENS, {
        variables: {
            userId: UserKeysData?.userKeys?.length > 0 ? UserKeysData?.userKeys[0].id : ""
        },
    });

    const handleClose = () => {
        updateParams({
            search: getNewSearchParamsString({
                keysToRemove: [
                    'openBackScreenEdit', 'newBackScreen'
                ]
            })
        });
    };

    const handleSave = async () => {

        // save screen in DB
        const newScreen = (await saveSingleBackScreen({
            variables: {
                backScreen: {
                    id: null,
                    name: screenName,
                    filters: [],
                    selectedRowTitle: '',
                    rows: [],
                    // isOwnedByUser: firstScreen.isOwnedByUser
                },
                userId: UserKeysData?.userKeys?.length > 0 ? UserKeysData?.userKeys[0].id : ""
            }
        }))?.data?.saveBackScreen;

        console.log({ newScreen });

        await refetch_backScreens();

        // navigate to screen

        await updateScreens({
            variables: {
                screens: [{
                    ...newScreen,
                    isOwnedByUser: true
                }]
            }
        }).then(r => {
            history.push({
                pathname: `/backscreen/${newScreen.name}/${newScreen.id}`
            });
        });

    }

    return (
        <Dialog open={!!allSearchParams.newBackScreen} onClose={handleClose}>
            <Paper>
                <Box
                    width={455}
                    maxWidth="calc(100vw - 65px)"
                >
                    <DialogTitle>
                        New Back Screen
                    </DialogTitle>
                    <DialogContent>

                        <Box display="flex" justifyContent="space-around">

                            <Box flex={1} pt={5}>
                                <form>
                                    <TextField
                                        label="Name"
                                        fullWidth
                                        // size="small"
                                        // variant="outlined"
                                        value={screenName}
                                        onChange={(event: any) => {
                                            setScreenName(event.target.value);
                                        }}
                                        name="name"
                                    />
                                </form>
                            </Box>
                        </Box>

                        <Box p={2}></Box>
                    </DialogContent>
                    <DialogActions>
                        {/* loading={saveSingleBackScreen_loading} */}
                        <Button onClick={handleSave} color="secondary" >
                            Create Back Screener
                        </Button>
                    </DialogActions>
                </Box>
            </Paper>
        </Dialog>
    );
}
